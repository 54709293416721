import React from "react";
import Homepage from "./pages/Homepage";

const App = () => {
	return (
		<div>
			<h1 className="text-3xl font-bold underline">Hello world!</h1>
			<Homepage />
		</div>
	);
};

export default App;
