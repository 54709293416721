import React from "react";

const Homepage = () => {
	return (
		<div>
			<h2>This is the homepage that's going to be on react.app</h2>
		</div>
	);
};

export default Homepage;
